<template>
  <section>
    <div v-if="exercises && exercises.length > 0">
      <v-card class=" p-3 bg-white rounded my-5 d-flex flex-row align-items-center border" flat v-for="(item, i) in exercises" :key="i" @click="handleClickExercise(item)">
        <v-avatar size="50" v-if="item.elogo">
          <img :src="item.elogo" alt="Exercises-logo"/>
        </v-avatar>
        <b-icon icon="book" width="50" height="50" class="bg-light pa-4 rounded-circle" variant="info" v-else></b-icon>
        <div class="ms-3">
          <span class="d-block f15 fw-bold">{{ item.etitle }}</span>

          <span class="d-block f13 grey--text">{{ item.drFirstName }} {{ item.drLastName }}</span>
          
          <div v-if="item.estatus == 'PAID' || item.estatus == 'FREE'">
            <span class="d-block primary--text f12" v-if="item.estatus == 'FREE'">
              {{ $t("FREE") }} ({{$t("Not answered")}})
            </span>
            <span class="d-block primary--text f12" v-else-if="item.estatus == 'PAID'">
              {{ $t("PAID") }} ({{$t("Not answered")}})
            </span>
          </div>
          <div v-else-if="item.estatus == 'PAYMENT_PENDING'">
            <span class="red--text f12" >
              {{ $t("PAYMENT PENDING") }} ({{(item.eprice || 0) | VMask(currencyMask)}} {{ $t("RIAL") }})
            </span>
          </div>

          <span class="d-block green--text f12" v-else-if="item.estatus == 'ANSWERED'">
            {{ $t("ANSWERED") }}
          </span>
        </div>
      </v-card>
    </div>

    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <v-icon>mdi-selection-ellipse spin</v-icon>
      </div>
      <div slot="no-more">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
      <div slot="no-results">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
    </infinite-loading>

  </section>
</template>
<script>
import apiService from "@/http/ApiService";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  data() {
    return {
      currencyMask,
      exercises: [],
      
      page: 1,
      size: 12,
    };
  },
  methods: {
    handleClickExercise(exercise) {
      this.$router.push({name: 'ExerciseDetails', params: {obj: {...exercise}, id: exercise.eid}})
    },
    getExercises(page, size) {
      apiService.gatExamExercise("EXERCISE", page, size)
      .then((response) => {
        this.exercises = response.data.content;
      });
    },
    infiniteHandler($state) {
      apiService.gatExamExercise("EXERCISE", this.page, this.size)
        .then((response) => {
          if (response.data.content.length) {
            response.data.content.forEach(element => {
              this.exercises.push(element);
            });

            this.page += 1;
            $state.loaded();
          }
          else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.getExercises(0, this.size);
  },
};
</script>