var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.exercises && _vm.exercises.length > 0
        ? _c(
            "div",
            _vm._l(_vm.exercises, function (item, i) {
              return _c(
                "v-card",
                {
                  key: i,
                  staticClass:
                    "p-3 bg-white rounded my-5 d-flex flex-row align-items-center border",
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickExercise(item)
                    },
                  },
                },
                [
                  item.elogo
                    ? _c("v-avatar", { attrs: { size: "50" } }, [
                        _c("img", {
                          attrs: { src: item.elogo, alt: "Exercises-logo" },
                        }),
                      ])
                    : _c("b-icon", {
                        staticClass: "bg-light pa-4 rounded-circle",
                        attrs: {
                          icon: "book",
                          width: "50",
                          height: "50",
                          variant: "info",
                        },
                      }),
                  _c("div", { staticClass: "ms-3" }, [
                    _c("span", { staticClass: "d-block f15 fw-bold" }, [
                      _vm._v(_vm._s(item.etitle)),
                    ]),
                    _c("span", { staticClass: "d-block f13 grey--text" }, [
                      _vm._v(
                        _vm._s(item.drFirstName) + " " + _vm._s(item.drLastName)
                      ),
                    ]),
                    item.estatus == "PAID" || item.estatus == "FREE"
                      ? _c("div", [
                          item.estatus == "FREE"
                            ? _c(
                                "span",
                                { staticClass: "d-block primary--text f12" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("FREE")) +
                                      " (" +
                                      _vm._s(_vm.$t("Not answered")) +
                                      ") "
                                  ),
                                ]
                              )
                            : item.estatus == "PAID"
                            ? _c(
                                "span",
                                { staticClass: "d-block primary--text f12" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PAID")) +
                                      " (" +
                                      _vm._s(_vm.$t("Not answered")) +
                                      ") "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : item.estatus == "PAYMENT_PENDING"
                      ? _c("div", [
                          _c("span", { staticClass: "red--text f12" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("PAYMENT PENDING")) +
                                " (" +
                                _vm._s(
                                  _vm._f("VMask")(
                                    item.eprice || 0,
                                    _vm.currencyMask
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.$t("RIAL")) +
                                ") "
                            ),
                          ]),
                        ])
                      : item.estatus == "ANSWERED"
                      ? _c("span", { staticClass: "d-block green--text f12" }, [
                          _vm._v(" " + _vm._s(_vm.$t("ANSWERED")) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
        _c(
          "div",
          { attrs: { slot: "spinner" }, slot: "spinner" },
          [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-more" }, slot: "no-more" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-results" }, slot: "no-results" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }